import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { css } from '@emotion/core'
import { Container } from '@material-ui/core'

const PageHeader = ({ pageTitle }) => { 
const data = useStaticQuery(graphql`
  query{
    file(relativePath: { eq: "images/standardHeader.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`
);

return (
  <BackgroundImage
    fluid={data.file.childImageSharp.fluid}
    css={theme => css`
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: c;
      color: ${theme.colors.white};
      margin-bottom: 25px
    `
    }
  >
    <Container>
      <h1>{pageTitle}</h1>
    </Container>
  </BackgroundImage>)
}

export default PageHeader;