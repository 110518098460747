import React, { Fragment } from 'react'
import { css } from "@emotion/core"
import { Link } from 'gatsby'

const Breadcrumbs = ({ url }) => {
  const breadcrumbs = url.split('/').filter((x) => x !== '');

  if(breadcrumbs.length > 0){
    var concurrentURL = "/"
    return (
      <div
        css={theme => css`
          margin-bottom: 12px;
          .breadcrumbLink {
            text-transform: capitalize;
            font-weight: 600;
            color: ${theme.colors.dgrey}
          }
          .breadcrumbLinkSep {
            margin: 0 5px;
            color: ${theme.colors.lgrey}
          }
          .breadcrumbLinkSep:last-child {
            display: none;
          }
        `}
      >
        <span
          className="breadcrumbLinkSep"
        >/</span>
        <Link
          to='/'
          className="breadcrumbLink"
        >Home</Link>
        <span
          className="breadcrumbLinkSep"
        >/</span>
        {breadcrumbs.map((breadcrumb, index) => {
          concurrentURL = concurrentURL.concat(`${breadcrumb}/`);
          if(index + 1 != breadcrumbs.length){
            return (
              <Fragment>
                <Link 
                  to={concurrentURL}
                  className="breadcrumbLink"
                  >{breadcrumb}</Link>
                <span
                  className="breadcrumbLinkSep"
                >/</span>
              </Fragment>
            )
          } else {
            return null
          }
        })}
      </div>
    )
  }

  return null
}

export default Breadcrumbs