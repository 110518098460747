import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeader from '../components/Blog/pageHeader'
import Breadcrumbs from '../components/MainComponents/Breadcrumbs'

const TextPage = ({ data, location }) => <Layout
  PageHeader={() => <PageHeader pageTitle={data.title} />}
>
  <SEO
    title={data.title}
    url={location.pathname}
  />
  <Breadcrumbs url={location.pathname} />
  <main>
    <div
      dangerouslySetInnerHTML={{ __html: data.content }}
    />
  </main>
</Layout>

export default TextPage